/* eslint-disable no-console */
import {
	default as React,
	useEffect,
	useState,
	useReducer,
	useRef,
	useCallback
} from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
	TextField,
	Button,
	FormControl,
	InputLabel,
	Select,
	Input,
	Chip,
	MenuItem,
	Tooltip,
	IconButton,
	Snackbar,
	CircularProgress
} from "@mui/material";
import { Star, Delete, Close } from "@mui/icons-material";
import { default as Slider } from "react-slick";
import PlacesAutocomplete, {
	geocodeByPlaceId,
	getLatLng
} from "react-places-autocomplete";

import {
	useSelectedBar,
	asyncGetBarImages,
	useBarUtils
} from "../actions/bars";
import {
	useCategories,
	useFetchCategories
} from "../actions/categories";
import { useUserRoles } from "../globalstore";
import { useSetHeaderTitle } from "../actions/headerContext";
import { THEME_MAP } from "../theme";
import { auth } from "../services/firebase";
import { useFetchRegions, useRegions } from "../actions/regions";
import OpeningHoursForm from '../components/OpeningHoursForm';
import {
	EMAIL_REQUIRED_TEXT,
	NAME_REQUIRED_TEXT,
	OWNER_EMAIL_REQUIRED_TEXT,
	PHONE_NUMBER_VALID_TEXT,
	PHONE_REGEX,
	SELECT_ADDRESS_TEXT,
	WEBSITE_URL_REGEX,
	WEBSITE_VALID_TEXT
} from "../constants";
import { useUserUtils } from "../actions/users";
import firebase from "firebase";
import dayjs from "dayjs";

export function BarDetail() {
	const history = useHistory();
	const { id } = useParams();
	const isNew = id === "new";
	const {
		userRoles: { isAdmin }
	} = useUserRoles();

	const barSnapshot = useSelectedBar();
	const { fetchBarById } = useBarUtils();

	const [bar, setBar] = useState();
	const [isFetching, setIsFetching] = useState();
	const [imgUrls, setImgUrls] = useState([]);

	const [isOpen, setIsOpen] = useState(false);
	const [note, setNote] = useState("");

	const setHeaderTitle = useSetHeaderTitle();

	const closeNote = (_event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setNote("");
		setIsOpen(false);
	};

	const openNote = (message) => {
		setNote(message);
		setIsOpen(true);
	};

	useEffect(() => {
		// fetch the images if we have a bar, or fetch the bar if we don't have it yet
		if (barSnapshot && barSnapshot.id === id) {
			let _bar = barSnapshot.barName
				? barSnapshot
				: barSnapshot.data();
			setBar(_bar);
			setImgUrls([]);
			if (_bar?.barImages) {
				asyncGetBarImages(id, _bar).then((res) => {
					if (!res || res.length < 1) {
						return;
					}

					setImgUrls([...res]);
				});
			}
		} else if (id) {
			setBar(null);
			if (id !== "new") {
				setIsFetching(true);
				fetchBarById(id).then(() => setIsFetching(false));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [barSnapshot, id]);

	useEffect(() => {
		setHeaderTitle(id === "new" && "Create new bar");
	}, [id, setHeaderTitle]);

	if (isFetching)
		return (
			<Container className={"is-loading"}>
				<LoadingFog className={"loading-fog is-loading"}>
					<CircularProgress color="primary" />
				</LoadingFog>
			</Container>
		);

	return (
		<Container>
			<div className="bar-actions">
				{isAdmin && !isNew && (
					<Button
						onClick={() => history.push(`/managers/${id}`)}
					>
						Edit Managers
					</Button>
				)}
			</div>
			<BarInfoSection bar={bar} openNote={openNote} />
			{!isNew && (
				<BarImgSection
					bar={bar}
					barImages={imgUrls}
					openNote={openNote}
				/>
			)}
			{!isNew && <CategorySection bar={bar} openNote={openNote} />}
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				open={isOpen}
				autoHideDuration={3000}
				onClose={closeNote}
				message={note}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={closeNote}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			/>
		</Container>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;

	padding: 24px;
	overflow: auto;
	position: relative;

	&.is-loading {
		overflow: hidden;
	}

	.bar-actions {
		max-width: 800px;
		margin: auto;
		display: flex;
		justify-content: flex-end;
	}
`;

const LoadingFog = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
	justify-content: center;
	background: rgba(255, 235, 235, 0.6);
	&.is-loading {
		display: flex;
	}
`;

const sliderSettings = {
	className: "bar-img-slider",
	infinite: false,
	slidesToShow: 1,
	speed: 500,
	variableWidth: true,
	centerMode: true,
	adaptiveHeight: true
};

const LoadingContainer = styled.div`
	max-width: 800px;
	margin: auto;
	background: ${THEME_MAP["color-basic-100"]};
	border-radius: 4px;
	border: 1px solid ${THEME_MAP["color-basic-400"]};
	min-height: 300px;
	padding: 16px 32px;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

function BarImgSection({ barImages = [], bar, openNote }) {
	const { uploadImage, deleteImage, markAsBannerImage } =
		useBarUtils();
	const { id: barId } = useParams();

	const [bannerImg, setBannerImg] = useState(null);
	const fileSelectRef = useRef(null);
	const [isUploading, setIsUploading] = useState(false);

	useEffect(() => {
		setBannerImg(
			bar?.barCoverImage !== "" ? bar?.barCoverImage : null
		);
	}, [bar]);

	const clickFileUpload = (e) => {
		e.preventDefault();
		// eslint-disable-next-line no-unused-expressions
		fileSelectRef?.current.click();
	};

	const handleFileSelect = () => {
		setIsUploading(true);
		const file = fileSelectRef.current.files[0];
		// we turn the image into a low fidelity jpeg
		resizeImage({
			file,
			maxSize: 600 // max size is the max size of the largest dimension on the image
		})
			.then(function (resizedImage) {
				uploadImage(barId, bar, resizedImage).then(
					({ fileName, downloadUrl }) => {
						// do some cleanup alert on success
						console.log({ fileName, downloadUrl });
						openNote("Image Uploaded successfully");
						setIsUploading(false);
					}
				);
			})
			.catch(function () {
				setIsUploading(false);
				openNote("Failed to upload Image");
			});
	};

	const handleImageDelete = (url) => {
		setIsUploading(true);
		deleteImage(url)
			.then(() => {
				openNote("Image deleted successfully");
				setIsUploading(false);
			})
			.catch(() => {
				openNote("Image couldn't be deleted");
				setIsUploading(false);
			});
	};

	const handleCoverImageSelect = (url, isBanner) => {
		setIsUploading(true);
		markAsBannerImage(url, isBanner)
			.then(() => {
				openNote("Banner Image Updated");
				setIsUploading(false);
			})
			.catch(() => {
				openNote("Image couldn't be Updated");
				setIsUploading(false);
			});
	};

	if (isUploading)
		return (
			<LoadingContainer>
				<CircularProgress />
			</LoadingContainer>
		);

	return (
		<BarImgContainer>
			{barImages.length > 0 ? (
				<Slider {...sliderSettings}>
					{barImages.map((url) => {
						let isBanner = url?.includes(bannerImg);
						return (
							<figure key={url}>
								<img
									alt=""
									key={url}
									src={url}
									height="268px"
								/>
								<figcaption>
									<Tooltip title="Select Banner Image">
										<IconButton
											onClick={() =>
												handleCoverImageSelect(
													url,
													isBanner
												)
											}
											size="large"
										>
											{isBanner ? (
												<Star color="inherit" />
											) : (
												<i className="material-icons">
													star_outline
												</i>
											)}
										</IconButton>
									</Tooltip>
									<Tooltip title="Delete Image">
										<IconButton
											onClick={() =>
												handleImageDelete(url)
											}
											size="large"
										>
											<Delete />
										</IconButton>
									</Tooltip>
								</figcaption>
							</figure>
						);
					})}
				</Slider>
			) : (
				<div className="no-image-holder">
					<h2>Upload an image so patrons can see you!</h2>
				</div>
			)}
			<div className="section-footer">
				<Button
					color="primary"
					variant="outlined"
					onClick={clickFileUpload}
				>
					Upload Image
				</Button>
				<input
					type="file"
					accept="image/*"
					ref={fileSelectRef}
					onChange={handleFileSelect}
				/>
			</div>
		</BarImgContainer>
	);
}

const BarImgContainer = styled.form`
	max-width: 800px;
	margin: auto;
	background: ${THEME_MAP["color-basic-100"]};
	border-radius: 4px;
	border: 1px solid ${THEME_MAP["color-basic-400"]};
	min-height: 300px;
	padding: 16px 32px;
	margin-bottom: 16px;

	figure {
		position: relative;
	}

	figcaption {
		opacity: 0;
		transition: opacity 0.35s ease-in-out;

		position: absolute;
		width: 100%;
		bottom: 0px;
		left: 0px;

		padding: 4px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		background-color: rgba(0, 0, 0, 0.4);

		.MuiIconButton-root {
			color: ${THEME_MAP["color-basic-100"]};
			&:hover {
				opacity: 0.6;
			}
		}
	}

	img {
		width: auto;
		height: 268px;
		opacity: 0.5;
		transition: opacity 0.35s ease-in-out;
	}

	.bar-img-slider {
		min-height: 272px;

		.slick-next:before,
		.slick-prev:before {
			color: ${THEME_MAP["color-primary-500"]};
		}
	}

	.no-image-holder {
		min-height: 272px;
		display: flex;
		align-items: center;
		justify-content: center;

		h2 {
			color: ${THEME_MAP["color-primary-500"]};
		}
	}

	.controls {
		opacity: 0;
	}

	.slick-center {
		img {
			opacity: 1;
		}
		.controls,
		figcaption {
			opacity: 1;
		}
	}

	.section-footer {
		padding-top: 16px;
		display: flex;
		justify-content: flex-end;
	}

	input[type="file"] {
		visibility: hidden;
		pointer-events: none;
		width: 1px;
		height: 1px;
		position: fixed;
		top: -10;
		left: -10;
	}
`;

function BarInfoSection({ bar, openNote }) {
	const selectedBar = useSelectedBar();
	const history = useHistory();
	const { currentUser } = auth;

	const { id } = useParams();
	const regions = useRegions();
	const { fetchRegions } = useFetchRegions();
	const isNew = id === "new";

	const { saveBar } = useBarUtils();

	const defaultOpeningHours = {
		monday: { isOpen: false, openTime: null, closeTime: null, 
			 openTime2: null, closeTime2: null },
		tuesday: { isOpen: false, openTime: null, closeTime: null, 
			 openTime2: null, closeTime2: null },
		wednesday: { isOpen: false, openTime: null, closeTime: null, 
			 openTime2: null, closeTime2: null },
		thursday: { isOpen: false, openTime: null, closeTime: null, 
			 openTime2: null, closeTime2: null },
		friday: { isOpen: false, openTime: null, closeTime: null, 
			 openTime2: null, closeTime2: null },
		saturday: { isOpen: false, openTime: null, closeTime: null, 
			 openTime2: null, closeTime2: null },
		sunday: { isOpen: false, openTime: null, closeTime: null, 
			 openTime2: null, closeTime2: null },
	};

	const [openingHours, setOpeningHours] = useState(defaultOpeningHours);
	  
	const [barState, barDispatch] = useReducer(
		barInfoReducer,
		emptyBarState,
		barInfoInit
	);

	const getRegions = useCallback(() => {
		fetchRegions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { fetchManagers, addManager } = useUserUtils();

	const [hoursHasChanged, setHoursHasChanged] = useState(false);

	useEffect(() => {
		selectedBar?.id && fetchManagers(selectedBar);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBar]);
	
	useEffect(() => {
		setHoursHasChanged(openingHours !== barState.hours);
	}, [openingHours,barState.hours]);
	
	useEffect(() => {
		getRegions();
		barDispatch({ type: "reset", payload: bar || emptyBarState });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bar]);

	const handleInputChange = (event) => {
		barDispatch({
			type: "update",
			payload: {
				name: event.target.name,
				value: event.target.value
			}
		});
	};

	const handleReset = () => {
		barDispatch({
			type: "reset"
		});
	};

	const handleSave = (e) => {
		e.preventDefault();
		const {
			...bar
		} = barState;

		bar.hours = openingHours;
		if (isNew) bar.createdBy = currentUser.uid;
		const errorMessage = validateData(bar);
		if (errorMessage) {
			openNote(errorMessage);
		} else {
			saveBar(id, bar, DAYS).then((result) => {
				barState.barId = isNew ? result?.id : id;
				addNewOwner(barState);
				openNote(
					`Successfully ${isNew ? "created" : "updated"} bar`
				);
			});
		}
	};

	const sendResetEmail = async () => {
		await firebase
			.auth()
			.sendPasswordResetEmail(barState.ownerEmail);
	};
	const addNewOwner = (barState) => {
		addManager({
			email: barState.ownerEmail.toLowerCase(),
			barId: barState.barId,
			barName: barState.barName,
			role: "owner"
		})
			.then(() => {
				sendResetEmail();
			})
			.catch((e) => {});
	};
	const handleCancel = (e) => {
		e.preventDefault();
		history.replace("/bars");
	};

	const validateData = (bar) => {
		let errorMessages = "";
		// validate barName is required
		if (!bar.barName?.trim()) {
			errorMessages = `${NAME_REQUIRED_TEXT}`;
		}

		if (!bar.barEmail?.trim()) {
			errorMessages = `${errorMessages}\n${EMAIL_REQUIRED_TEXT}`;
		}

		if (!bar.ownerEmail?.trim()) {
			errorMessages = `${errorMessages}\n${OWNER_EMAIL_REQUIRED_TEXT}`;
		}

		if (bar.barPhone && !PHONE_REGEX.test(bar.barPhone)) {
			errorMessages = `${errorMessages}\n${PHONE_NUMBER_VALID_TEXT}`;
		}

		if (bar.barUrl && !WEBSITE_URL_REGEX.test(bar.barUrl)) {
			errorMessages = `${errorMessages}\n${WEBSITE_VALID_TEXT}`;
		}
		
		// validate position data is required
		let [lat, lon] = [undefined, undefined];

		if (typeof bar.latitude !== "number") {
			lat = bar.latitude?.trim();
			lat = parseFloat(lat);
		} else {
			lat = bar.latitude;
		}

		if (typeof bar.longitude !== "number") {
			lon = bar.longitude?.trim();
			lon = parseFloat(lon);
		} else {
			lon = bar.longitude;
		}

		if (!lat || !lon) {
			errorMessages = `${errorMessages}\n${SELECT_ADDRESS_TEXT}`;
		} else {
			if (isNaN(lat) || isNaN(lon)) {
				errorMessages = `${errorMessages}\n${SELECT_ADDRESS_TEXT}`;
			}
		}
		Object.keys(bar.hours).forEach(day => {
			if (bar.hours[day].isOpen) {
				if (!bar.hours[day].openTime || isNaN(bar.hours[day].openTime) ||
            !bar.hours[day].closeTime || isNaN(bar.hours[day].closeTime)) {
          errorMessages = `${errorMessages}\n${day.charAt(0).toUpperCase() + day.slice(1)}: Opening and closing times are required.`;
				} else if (bar.hours[day].closeTime.isBefore(bar.hours[day].openTime)) {
					errorMessages = `${errorMessages}\n${day.charAt(0).toUpperCase() + day.slice(1)}: Closing time cannot be earlier than opening time.`;
				}
				
				if ((bar.hours[day].openTime2 && !bar.hours[day].closeTime2) || (!bar.hours[day].openTime2 && bar.hours[day].closeTime2)) {
					errorMessages = `${errorMessages}\n${day.charAt(0).toUpperCase() + day.slice(1)}: Both second opening and closing times must be provided together.`;
				} else if (bar.hours[day].openTime2 && bar.hours[day].closeTime2) {
					if (isNaN(bar.hours[day].openTime2) || isNaN(bar.hours[day].closeTime2)) {
						errorMessages = `${errorMessages}\n${day.charAt(0).toUpperCase() + day.slice(1)}: Second opening and closing times must be valid numbers.`;
					} else if (bar.hours[day].closeTime2.isBefore(bar.hours[day].openTime2)) {
						errorMessages = `${errorMessages}\n${day.charAt(0).toUpperCase() + day.slice(1)}: Second closing time cannot be earlier than second opening time.`;
					}
				}
			}
		});
		
		return errorMessages !== "" ? errorMessages : null; //Return null if there is no error message
	};
	function barInfoInit(barInfo) {
		if (window.location.pathname === '/bar/new') {
			setOpeningHours(defaultOpeningHours);
		}
		if (barInfo && barInfo.hours && Object.keys(barInfo.hours).length) {
			const orderedHours = {};
			DAYS.forEach(day => {
				const { isOpen, openTime, closeTime,
					openTime2, closeTime2 
				} = barInfo.hours[day];
				orderedHours[day] = { isOpen, openTime, closeTime,
					openTime2, closeTime2
				 };
			});
			DAYS.forEach(day => {
				if (barInfo.hours[day].openTime && barInfo.hours[day].openTime.seconds !== undefined) {
					orderedHours[day].openTime = dayjs(barInfo.hours[day].openTime.toDate());
				}
				if (barInfo.hours[day].closeTime && barInfo.hours[day].closeTime.seconds !== undefined) {
					orderedHours[day].closeTime = dayjs(barInfo.hours[day].closeTime.toDate());
				}
				if (barInfo.hours[day].openTime2 && barInfo.hours[day].openTime2.seconds !== undefined) {
					orderedHours[day].openTime2 = dayjs(barInfo.hours[day].openTime2.toDate());
				}
				if (barInfo.hours[day].closeTime2 && barInfo.hours[day].closeTime2.seconds !== undefined) {
					orderedHours[day].closeTime2 = dayjs(barInfo.hours[day].closeTime2.toDate());
				}
			});
			barInfo.hours = orderedHours;
			setOpeningHours(orderedHours);
		}
	
		return {
			original: barInfo,
			...barInfo,
			hasChanged: false,
			latitude: barInfo?.position?.geopoint?.latitude,
			longitude: barInfo?.position?.geopoint?.longitude,
		};
	}
	
	function barInfoReducer(state, action) {
		const { type, payload } = action;
		let hasChanged = false;
		switch (type) {
			case "reset":
				return barInfoInit(payload || state.original);
			case "update":
				hasChanged = true;
				return {
					...state,
					[payload.name]: payload.value,
					hasChanged
				};
			default:
				return state;
		}
	}

	return (
		<BarInfoContainer>
			<div className="form-section large">
				<TextField
					className="header-like"
					label="Bar Name"
					name="barName"
					fullWidth
					inputProps={{
						style: {
							fontSize: "1.8rem"
						},
						maxLength: 50
					}}
					value={barState.barName}
					onChange={handleInputChange}
					required
				/>

				<TextField
					label="Bar Description"
					name="barDescription"
					fullWidth
					multiline
					value={barState.barDescription}
					onChange={handleInputChange}
					inputProps={{
						maxLength: 500
					}}
				/>
				{/* <TextField
					label="Live Stream URL"
					name="liveUrl"
					fullWidth
					multiline
					value={barState.liveUrl}
					onChange={handleInputChange}
				/> */}
			</div>
			<div className="form-section basic-info">
				<TextField
					label="Bar Email"
					name="barEmail"
					fullWidth
					value={barState.barEmail}
					onChange={handleInputChange}
					required
				/>
				<TextField
					label="Owner Email"
					name="ownerEmail"
					fullWidth
					value={barState.ownerEmail}
					onChange={handleInputChange}
					required
				/>
				<TextField
					label="Bar Phone"
					name="barPhone"
					fullWidth
					value={barState.barPhone}
					onChange={handleInputChange}
					placeholder="123-232-2323"
				/>
				<TextField
					label="Bar Website"
					name="barUrl"
					fullWidth
					value={barState.barUrl}
					onChange={handleInputChange}
					placeholder="gobarhopper.com"
				/>
			</div>
			<div className="form-section hours">			
				<PlacesAutocomplete
					value={barState.barAddress}
					searchOptions={{
						componentRestrictions: {
							country: "us"
						}
					}}
					onChange={(value) => {
						barDispatch({
							type: "update",
							payload: {
								name: "barAddress",
								value: value
							}
						});
					}}
					onSelect={async (address, placeID) => {
						const addressFormat =
							await geocodeByPlaceId(placeID);
						const latlng = await getLatLng(addressFormat[0]);
						await Promise.all([
							barDispatch({
								type: "update",
								payload: {
									name: "barAddress",
									value: addressFormat[0].formatted_address
								}
							}),
							barDispatch({
								type: "update",
								payload: {
									name: "latitude",
									value: latlng.lat
								}
							}),
							barDispatch({
								type: "update",
								payload: {
									name: "longitude",
									value: latlng.lng
								}
							})
						]);
					}}
				>
					{({
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading
					}) => (
						<div>
							<TextField
								placeholder="123 Main Street, Anytown, USA 12345"
								style={{ width: "100%" }}
								{...getInputProps({
									label: "Bar address",
									className: "location-search-input"
								})}
								required
							/>
							<div className="autocomplete-dropdown-container">
								{loading && (
									<div className="suggestion-item">
										Loading...
									</div>
								)}
								{suggestions.map((suggestion) => {
									const className = suggestion.active
										? "suggestion-item--active"
										: "suggestion-item";
									// inline style for demonstration purpose
									const style = suggestion.active
										? {
												backgroundColor: "#fafafa",
												cursor: "pointer"
											}
										: {
												backgroundColor: "#ffffff",
												cursor: "pointer"
											};
									return (
										<div
											key={suggestion.id}
											{...getSuggestionItemProps(
												suggestion,
												{
													className,
													style
												}
											)}
										>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>
				{
					<Select
						value={barState.regionId || ""}
						onChange={(event) => {
							barDispatch({
								type: "update",
								payload: {
									name: "regionId",
									value: event.target.value
								}
							});
						}}
						displayEmpty
						placeholder="Select Region"
						inputProps={{ "aria-label": "Current Role" }}
						color="primary"
					>
						<MenuItem disabled value="">
							-- Select Region --
						</MenuItem>
						{regions.map((region) => (
							<MenuItem key={region.id} value={region.id}>
								{region.Name}
							</MenuItem>
						))}
					</Select>
				}
			</div>
			<div className="form-section large">
				<label>Open/close date and time</label>
				<OpeningHoursForm
					openingHours={openingHours}
					setOpeningHours={setOpeningHours}
				/>
			</div>
			<div className="form-footer">
				{isNew ? (
					<Button
						color="primary"
						variant={"outlined"}
						onClick={handleCancel}
					>
						Cancel
					</Button>
				) : (barState.hasChanged || hoursHasChanged) ? (
					<Button
						color="primary"
						variant={"outlined"}
						onClick={handleReset}
					>
						Clear Changes
					</Button>
				) : null}
				<Button
					color="primary"
					variant={
						barState.hasChanged || hoursHasChanged || isNew
							? "contained"
							: "outlined"
					}
					onClick={handleSave}
				>
					Save
				</Button>
			</div>
		</BarInfoContainer>
	);
}

const emptyBarState = {
	barName: "",
	barId: "",
	barEmail: "",
	ownerEmail: "",
	barPhone: "",
	barUrl: "",
	hours: [],
	barAddress: "",

	barCoverImage: "",
	barImages: [],
	barDescription: "",
	liveUrl: "",

	barOpenDays: "",
	barClosingHours: "",
	barOpeningHours: "",
	hasChanged: false,

	position: {
		geopoint: {
			latitude: 0,
			longitude: 0
		}
	}
};




const BarInfoContainer = styled.form`
	max-width: 800px;
	margin: auto;
	background: ${THEME_MAP["color-basic-100"]};
	border-radius: 4px;
	border: 1px solid ${THEME_MAP["color-basic-400"]};
	padding: 16px 24px;

	min-height: 100px;
	margin-bottom: 16px;

	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.form-section {
		display: flex;
		flex-direction: column;
		width: 48%;
		margin-bottom: 32px;

		&.large {
			width: 100%;
		}

		& > div {
			margin: 8px 0;
		}
	}

	.image-uploads {
		background: ${THEME_MAP["color-basic-300"]};
		border-radius: 3px;
		height: 300px;

		display: flex;
		align-items: center;
		justify-content: space-evenly;

		img {
			height: 268px;
			width: auto;
		}
	}

	.form-footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		& > button {
			margin-left: 16px;
		}
	}
`;

const emptyCategories = {
	monday: {},
	tuesday: {},
	wednesday: {},
	thursday: {},
	friday: {},
	saturday: {},
	sunday: {}
};
const DAYS = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday"
];

function CategorySection({ bar, openNote }) {
	const categorySnapshots = useCategories();
	const fetchCategories = useFetchCategories();
	const { saveBarCategories } = useBarUtils();

	const [categories, setCategories] = useState([]);
	const [activeCategory, setActiveCategory] = useState();
	const [selectedSubcategories, setSubcategories] =
		useState(emptyCategories);
	const [hasChanged, setHasChanged] = useState(false);

	const activeName = activeCategory?.categoryName || "default";

	useEffect(() => {
		setSubcategories(bar?.categories || emptyCategories);
		setHasChanged(false);
	}, [bar]);

	useEffect(() => {
		if (categorySnapshots.length < 1) fetchCategories();
		setCategories(
			categorySnapshots.map((cat, idx) => {
				let category = cat.data();
				if (!activeCategory && idx === 0)
					setActiveCategory(category);
				return category;
			})
		);
	}, [categorySnapshots, activeCategory, fetchCategories]);

	const handleCategorySelect = (day, catName, event) => {
		setHasChanged(true);
		setSubcategories((current) => ({
			...current,
			[day]: {
				...current[day],
				[catName]: event.target.value
			}
		}));
	};

	const handleSave = (e) => {
		e.preventDefault();
		// do the stuff
		saveBarCategories(selectedSubcategories).then(() => {
			openNote("Categories updated successfully");
		});
	};

	const handleReset = (e) => {
		e.preventDefault();
		setSubcategories(bar?.categories || emptyCategories);
		setHasChanged(false);
	};

	return (
		<CategoryContainer>
			<h4>CATEGORY & SERVICES</h4>
			<div className="tab-selectors">
				{categories.map((category, index) => {
					return (
						<Button
							key={category.categoryName}
							variant={
								activeName === category.categoryName
									? "contained"
									: "outlined"
							}
							color={
								!activeCategory && index === 0
									? "primary"
									: activeName === category.categoryName
										? "primary"
										: "info"
							}
							onClick={() => setActiveCategory(category)}
						>
							{category.categoryName}
						</Button>
					);
				})}
			</div>
			<div className="select-container">
				{DAYS.map((day) => (
					<FormControl key={day} className="form-control">
						<InputLabel id="category-select">{day}</InputLabel>
						<Select
							labelId="category-select"
							id="category-multi-select"
							multiple
							value={
								selectedSubcategories[day]?.[activeName] || []
							}
							onChange={(event) =>
								handleCategorySelect(day, activeName, event)
							}
							input={<Input />}
							renderValue={(selected) => (
								<div className="chip-container">
									{selected.map((value) => (
										<Chip key={value} label={value} />
									))}
								</div>
							)}
							fullWidth
						>
							{activeCategory?.subcategories?.map((sub) => (
								<MenuItem key={sub} value={sub}>
									{sub}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				))}
			</div>
			<div className="form-footer">
				{hasChanged && (
					<Button
						color="primary"
						variant={"outlined"}
						onClick={handleReset}
					>
						Clear Changes
					</Button>
				)}
				<Button
					color="primary"
					variant={hasChanged ? "contained" : "outlined"}
					onClick={handleSave}
				>
					Save
				</Button>
			</div>
		</CategoryContainer>
	);
}
const CategoryContainer = styled.section`
	max-width: 800px;
	margin: auto;
	background: ${THEME_MAP["color-basic-100"]};
	border-radius: 4px;
	border: 1px solid ${THEME_MAP["color-basic-400"]};
	padding: 16px 24px;

	min-height: 100px;

	h4 {
		margin: 8px 0 24px;
	}

	.select-container > div {
		margin: 16px 0;
	}

	.tab-selectors {
		button {
			margin-right: 16px;
		}
	}

	.form-control {
		display: block;
		width: 100%;
		min-width: 200px;

		label {
			text-transform: capitalize;
		}
	}

	.chip-container {
		display: flex;
		flex-wrap: wrap;

		& > div {
			margin-right: 4px;
		}
	}

	.form-footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		& > button {
			margin-left: 16px;
		}
	}
`;

/**
 * promise that resolves to a resized image
 */
const resizeImage = function (settings) {
	const file = settings.file;
	const maxSize = settings.maxSize;
	const reader = new FileReader();
	const image = new Image();
	const canvas = document.createElement("canvas");

	const dataURItoBlob = function (dataURI) {
		const bytes =
			dataURI.split(",")[0].indexOf("base64") >= 0
				? atob(dataURI.split(",")[1])
				: unescape(dataURI.split(",")[1]);
		const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
		const max = bytes.length;
		const ia = new Uint8Array(max);
		for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
		return new Blob([ia], { type: mime });
	};

	const resize = function () {
		let width = image.width;
		let height = image.height;

		if (width > height) {
			if (width > maxSize) {
				height *= maxSize / width;
				width = maxSize;
			}
		} else {
			if (height > maxSize) {
				width *= maxSize / height;
				height = maxSize;
			}
		}

		canvas.width = width;
		canvas.height = height;
		canvas.getContext("2d").drawImage(image, 0, 0, width, height);
		const dataUrl = canvas.toDataURL("image/jpeg");
		return dataURItoBlob(dataUrl);
	};

	return new Promise(function (resolve, reject) {
		// If the file type doesn't match chuck it
		if (!file.type.match(/image.*/)) {
			reject(new Error("Not an image"));
			return;
		}

		reader.onload = function (readerEvent) {
			image.onload = function () {
				return resolve(resize());
			};

			image.src = readerEvent.target.result;
		};

		reader.readAsDataURL(file);
	});
};
